<template>
  <div v-if="showMeetingBooked" class="badge badge--meeting-booked">
    <div class="badge__white-dot"></div>
    <div class="badge__text">Meeting booked</div>
  </div>

  <div
    v-else
    class="badge"
    :class="{
      'badge--ringing': status === 'ringing' || status === 'initiated',
      'badge--pending': status === 'pending',
      'badge--in-progress': status === 'in-progress',
      'badge--connected': status === 'connected',
      'badge--voicemail': status === 'left-voicemail',

      'badge--completed':
        status === 'completed' ||
        status === 'failed' ||
        status === 'busy' ||
        status === 'no-answer' ||
        status === 'canceled' ||
        status === 'voicemail',
      'badge--error':
        errorStatus === 'no number' ||
        errorStatus === 'invalid number' ||
        errorStatus === 'not-authorized' ||
        errorStatus === 'country mismatch'
    }"
  >
    <div
      :class="{
        'badge__white-dot':
          status === 'ringing' ||
          status === 'initiated' ||
          status === 'in-progress' ||
          status === 'completed' ||
          status === 'connected' ||
          status === 'voicemail' ||
          status === 'left-voicemail' ||
          status === 'failed' ||
          status === 'busy' ||
          status === 'no-answer' ||
          status === 'canceled' ||
          errorStatus === 'not-authorized' ||
          errorStatus === 'no number' ||
          errorStatus === 'invalid number' ||
          errorStatus === 'country mismatch',
        'badge__grey-dot': status === 'pending'
      }"
    ></div>
    <div class="badge__text">
      {{ errorStatus || status }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  status: {
    type: String,
    required: true
  },
  errorStatus: {
    type: String
  },
  meetingBooked: {
    type: Boolean,
    required: true
  },
  canceledCallsCount: {
    type: Number,
    required: true
  }
})

const showMeetingBooked = computed(() => {
  return (
    props.meetingBooked &&
    props.status !== 'in-progress' &&
    props.status !== 'initiated' &&
    props.status !== 'ringing'
  )
})
</script>

<style lang="scss">
.badge {
  border-radius: 16px;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  transition:
    color 0.27s ease,
    background 0.27s ease;
}
.badge__grey-dot {
  width: 6px;
  height: 6px;
  background: #364254;
  margin-right: 6px;
  border-radius: 50%;
}

.badge__white-dot {
  width: 6px;
  height: 6px;
  background: #fff;
  margin-right: 6px;
  border-radius: 50%;
}

.badge--ringing {
  color: #fff;
  background: #ffcd00;
}

.badge--pending {
  color: #364254;
  background: #f2f4f7;
}
.badge--in-progress {
  color: #fff;
  background: #14ba6d;
}
.badge--completed {
  color: #fff;
  background: #ababab;
}

.badge--connected {
  color: #fff;
  background: #14ba6d;
}

.badge--voicemail {
  color: #fff;
  background: #4a4a4a;
}

.badge--meeting-booked {
  color: #fff;
  background: #9e00ff;
}

.badge--error {
  color: #fff;
  background: #ff3e32;
}

.badge__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &:first-letter {
    text-transform: capitalize;
  }
}
</style>
