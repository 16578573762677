import './assets/main.scss'

import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './services/axiosService' // This ensures Axios is configured when the app starts
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import ToastService from 'primevue/toastservice'
import 'primeicons/primeicons.css'
import * as Sentry from '@sentry/vue'
import versionCheck from './services/versionCheck'

const pinia = createPinia()

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const app = createApp(App)

const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    }
  }
})

Sentry.init({
  app,
  dsn: 'https://51df7cc48a897fe336e7163d4068d0e8@o4507021503299584.ingest.us.sentry.io/4507021690667008',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/tryminari\.xyz\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    if (location.hostname === 'localhost') {
      return null
    }

    if (event.user && event.user.userCompanyId === 1) {
      return null // Drop the event
    }
    return event // Return the event if it doesn't match the filter
  }
})

// Now the app has started!

app.component('VueDatePicker', VueDatePicker)

app.use(versionCheck)

app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      prefix: 'p',
      darkModeSelector: '.my-app-dark',
      cssLayer: true
    }
  }
})
app.directive('tooltip', Tooltip)
app.use(ToastService)
app.use(ConfirmationService)

app.use(pinia)

app.use(router)

app.mount('#app')
