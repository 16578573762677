// src/stores/auth.js
import { defineStore } from 'pinia'
import axios from '../services/axiosService'
import { useSessionStore } from './sessionStore'
import { useSalesforceStore } from './salesforceStore'
import { useHubspotStore } from './hubspotStore'
import { usePhoneStore } from './phone'
import { useVoicemailStore } from './voicemail'
import { setUser, clearUser } from '../services/sentryUser'
import { usePipedriveStore } from './pipedriveStore'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    userId: null,
    userName: null,
    userEmail: null,
    userCompanyId: null,
    isAuthenticated: false,
    isInitialized: false
  }),
  actions: {
    setAuthStatus({ userId, userName, userEmail, userCompanyId }) {
      this.userId = userId
      this.userName = userName
      this.userEmail = userEmail
      this.userCompanyId = userCompanyId
      this.isAuthenticated = !!userId
    },
    async logOut() {
      try {
        console.log('Logging out')
        // Send a request to the log out endpoint
        await axios.get('/api/log-out')

        this.isAuthenticated = false
        setTimeout(() => {
          this.$reset()

          const sessionStore = useSessionStore()
          sessionStore.reset()

          const salesforceStore = useSalesforceStore()
          salesforceStore.reset()

          const hubspotStore = useHubspotStore()
          hubspotStore.reset()

          const phoneStore = usePhoneStore()
          phoneStore.reset()

          const voicemailStore = useVoicemailStore()
          voicemailStore.reset()

          clearUser()
        }, 200)
      } catch (error) {
        console.error('Logout failed:', error)
      }
    },
    async handleUnauthorizedAccess() {
      // Handle 401 Unauthorized scenarios
      if (this.isAuthenticated) {
        await this.logOut()

        this.$router.push('/login')
      }
    },
    async initializeAuth() {
      try {
        const response = await axios.get('/api/init')
        this.setAuthStatus(response.data) // Assuming the response contains the userId

        const sessionStore = useSessionStore()
        sessionStore.checkActiveSession(response.data.activeSession)
        sessionStore.setUserSetting(response.data.userSetting)

        const salesforceStore = useSalesforceStore()
        salesforceStore.setConnected(response.data.isSalesforceConnected)

        const hubspotStore = useHubspotStore()
        hubspotStore.setConnected(response.data.isHubspotConnected)

        const phoneStore = usePhoneStore()
        phoneStore.setAuthorizedCountries(response.data.authorizedCountries)

        const pipedriveStore = usePipedriveStore()
        pipedriveStore.setConnected(response.data.isPipedriveConnected)

        this.isInitialized = true

        setUser({
          id: response.data.userId,
          username: response.data.userName,
          email: response.data.userEmail,
          companyId: response.data.userCompanyId
        })
        console.log('app initialized')
      } catch (error) {
        console.log('error initializing', error)
        this.setAuthStatus({})
      }
    }
  }
})
