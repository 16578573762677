import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export function parsePhoneNumberE164(phoneNumber) {
  let parsedPhoneNumber
  try {
    parsedPhoneNumber = phoneUtil.parse(phoneNumber)
  } catch (error) {
    throw 'Invalid phone number format'
  }

  // Format the parsed number to E.164 format
  return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
}

export function formatPhoneNumber(phoneNumber, defaultRegion = 'US') {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, defaultRegion)
    const formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)

    return formattedNumber
  } catch (error) {
    console.error('Error parsing phone number:', error)
    return null
  }
}

export function assertIsPhoneNumberValid(phoneNumber) {
  try {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
    if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
      throw 'Invalid phone number'
    }
  } catch (error) {
    throw 'Invalid phone number format'
  }
}

export function assertIsPhoneNumberAuthorized(phoneNumber, authorizedCountryCodes) {
  try {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
    const regionCode = phoneUtil.getRegionCodeForNumber(parsedPhoneNumber)
    if (!authorizedCountryCodes.includes(regionCode)) {
      throw 'Country unauthorized'
    }
  } catch (error) {
    console.error(error)
    throw 'Error checking phone number authorization'
  }
}

export function assertIsSameCountry(from, to) {
  const toTrimmed = to.trim()
  const fromCountryCode = phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(from))
  const toCountryCode = phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(toTrimmed))

  // Check if 'from' and 'to' phone numbers are from the same country
  if (fromCountryCode !== toCountryCode) {
    throw 'unauthorized_different_countries'
  }
}
