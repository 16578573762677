<template>
  <div class="call-logs-table">
    <div v-if="!loading" class="prime-table">
      <DataTable
        :value="callLogs"
        style="width: 100%"
        scrollable
        scrollHeight="flex"
        tableStyle="padding-bottom: 12px"
        :paginator="true"
        :rows="pageSize"
        :totalRecords="totalRecords"
        :lazy="true"
        :first="(currentPage - 1) * pageSize"
        @page="onPageChange"
      >
        <template #empty>
          <div class="empty-message">No data</div>
        </template>
        <Column header="User" headerClass="data-table-header" bodyClass="cell cell-first-column">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.userName }}
            </div>
          </template>
        </Column>
        <Column
          header="Contact"
          headerClass="data-table-header"
          bodyClass="cell cell-first-column"
          style="min-width: 195px; display: flex"
        >
          <template #body="slotProps">
            <div class="username-link" @click="openContactModal(slotProps.data)">
              {{ slotProps.data.prospectName }}
            </div>
          </template>
        </Column>
        <Column header="Call status" headerClass="data-table-header" bodyClass="cell cell--badge">
          <template #body="slotProps">
            <DialStatusBadge
              :status="slotProps.data.callStatus"
              :meeting-booked="!!slotProps.data.meetingBooked"
              :canceled-calls-count="0"
            />
          </template>
        </Column>
        <Column header="Recording" headerClass="data-table-header" bodyClass="cell cell--badge">
          <template #body="slotProps">
            <div v-if="slotProps.data.recordingLoading" class="cell--loader">
              <Loader :size="20"></Loader>
            </div>
            <div
              v-else-if="slotProps.data.noRecording || slotProps.data.callDirection !== 'outgoing'"
            >
              No recording
            </div>
            <div v-else-if="slotProps.data.callStatus !== 'connected'">-</div>
            <div
              class="cell--recording-link"
              v-else-if="!slotProps.data.audioUrl"
              @click="loadRecording(slotProps.data)"
            >
              <div class="link">Play recording</div>
            </div>
            <audio v-else :id="`player_${slotProps.data.callSid}`" controls>
              <source :src="slotProps.data.audioUrl" type="audio/mp3" />
            </audio>
          </template>
        </Column>
        <Column
          field="fromPhoneNumber"
          header="From"
          headerClass="data-table-header"
          bodyClass="cell"
        />
        <Column
          field="toPhoneNumber"
          header="To"
          headerClass="data-table-header"
          bodyClass="cell"
        />
        <Column header="Start date" headerClass="data-table-header" bodyClass="cell">
          <template #body="bodyProps">
            {{ formatDate(bodyProps.data.startDate) }}
          </template>
        </Column>
        <Column header="End date" headerClass="data-table-header" bodyClass="cell">
          <template #body="bodyProps">
            {{ formatDate(bodyProps.data.endDate) }}
          </template></Column
        >
        <Column
          field="callDurationFormatted"
          header="Duration"
          headerClass="data-table-header"
          bodyClass="cell"
        />
      </DataTable>
    </div>
    <div v-else class="table__row-group--loading">
      <Loader></Loader>
    </div>
  </div>
</template>

<script setup>
import { nextTick } from 'vue'
import { formatDate } from '../helpers/date'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Loader from './Loader.vue'
import axios from '../services/axiosService'
import Plyr from 'plyr'
import DialStatusBadge from './DialStatusBadge.vue'
import { useContactStore } from '../stores/contactStore'
import { usePhoneStore } from '../stores/phone'
import { useSessionStore } from '../stores/sessionStore'

const emit = defineEmits(['update:page'])

const onPageChange = (event) => {
  emit('update:page', { currentPage: event.page + 1, pageSize: event.rows })
}

const props = defineProps({
  loading: {
    required: true,
    type: Boolean
  },
  callLogs: {
    required: true,
    type: Array
  },
  currentPage: {
    required: true,
    type: Number
  },
  pageSize: {
    required: true,
    type: Number
  },
  totalRecords: {
    required: true,
    type: Number
  }
})

async function loadRecording(call) {
  const callLog = props.callLogs.find((item) => item.callSid === call.callSid)
  try {
    callLog.recordingLoading = true
    // Assuming call.call_sid is available
    const response = await axios.get(`/api/call-logs/recording/${call.callSid}`, {
      responseType: 'blob'
    })
    if (response.data) {
      // Create a URL from the blob
      const url = URL.createObjectURL(response.data)
      callLog.audioUrl = url
    }
  } catch (error) {
    console.error('Failed to load recording:', error)
    callLog.noRecording = true
  } finally {
    callLog.recordingLoading = false
    nextTick(() => {
      const player = new Plyr(`#player_${call.callSid}`, {
        controls: [
          'play-large', // The large play button in the center
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          // 'current-time', // The current time of playback
          'duration', // The full duration of the media
          'download' // Show a download button with a link to either the current source or a custom URL you specify in your options
        ],
        autoplay: true
      })
      const downloadButton = player.elements.buttons.download
      if (downloadButton) {
        downloadButton.setAttribute('download', `${callLog.prospectName}.mp3`)
      }
    })
  }
}

const contactStore = useContactStore()

const phoneStore = usePhoneStore()
const sessionStore = useSessionStore()

async function openContactModal(contact) {
  contactStore.contactModalLoading = true
  await phoneStore.initContactList()
  if (!phoneStore.canCall) {
    await phoneStore.initializedDevice()
  }

  if (!sessionStore.fromPhoneNumber.id) {
    await sessionStore.initCurrentSessionSetting()
  }

  await contactStore.fetchContact({
    id: contact.sourceId,
    ...contact
  })
  contactStore.contactModalLoading = false
}
</script>

<style lang="scss">
.call-logs-table {
  .username-link {
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;
    font-weight: 500;
    margin-left: -6px;
    &:hover {
      background: rgba(55, 53, 47, 0.08);
      cursor: pointer;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  align-items: start;

  border: 1px solid #eaecf0;
  border-radius: 8px;

  min-height: 0;
  flex: 1 1 auto;

  .cell {
    &:hover {
      cursor: unset;
    }

    &--badge {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .prime-table {
    min-height: 0;
    flex: 1 1 auto;
    width: 100%;
    border-radius: 8px;
  }

  .table__row-group--loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .empty-message {
    color: #667085;
    font-size: 14px;
    vertical-align: middle;
  }

  .p-datatable-table-container {
    border-radius: 8px;
  }
  .cell--loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 26px;

    transition: 0.05s ease;
  }
  .cell--recording-link {
    display: flex;
    align-items: center;
    min-height: 26px;

    transition: 0.05s ease;

    .link {
      margin-left: -6px;
      color: #0070ff;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 4px;
      font-weight: 500;
      &:hover {
        background-color: rgba(0, 123, 255, 0.1);
        cursor: pointer;
      }
    }
  }
  .p-datatable-paginator-bottom {
    border: none;
  }
}
</style>
