<template>
  <div class="prospect__task" :class="{ 'prospect__task--pinned': taskCopy.pinned }">
    <div class="activity-title-section">
      <div class="title">
        Task
        <pinned-badge v-if="taskCopy.pinned" class="pinned-badge" />
      </div>
      <div class="date">
        {{ showTaskTimePicker ? formatDate(taskCopy.date) : formatDateWithoutTime(taskCopy.date) }}
      </div>
    </div>
    <div class="task__header">
      <div
        class="status-input"
        :class="{
          'status-input--unchecked': taskCopy.status === 'NOT_STARTED',
          'status-input--checked': taskCopy.status === 'COMPLETED'
        }"
        @click="toggleTaskStatus(taskCopy)"
      >
        <checkmark-icon class="checkmark-icon" size="20" />
      </div>

      <input
        class="custom-input--title"
        v-model="taskCopy.title"
        :class="{ 'task__title--checked': taskCopy.status === 'COMPLETED' }"
        @blur="updateTask(taskCopy.id, 'title', taskCopy.title)"
      />
    </div>
    <div class="editor-item">
      <div class="editor-item__title">Due date</div>
      <date-selector
        :showTimePicker="showTaskTimePicker"
        @update:value="($events) => updateTaskDate(taskCopy.id, $events)"
        :initial-value="new Date(taskCopy.date)"
      />
    </div>

    <div class="editor-item" v-if="showTaskType">
      <div class="editor-item__title">Task type</div>
      <dropdown
        :items="contactStore.taskTypeList[importSource]"
        :initial-value="buildTaskType(taskCopy.taskType)"
        :min-width="120"
        @update:value="($events) => updateTaskType(taskCopy.id, $events)"
      />
    </div>
    <Textarea
      v-model="taskCopy.body"
      autoResize
      rows="2"
      @blur="updateTask(taskCopy.id, 'body', taskCopy.body)"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { formatDate, formatDateWithoutTime } from '@/helpers/date'
import DateSelector from './DateSelector.vue'
import PinnedBadge from './PinnedBadge.vue'
import Dropdown from './Dropdown.vue'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import Textarea from 'primevue/textarea'
import { isEqual, parseISO } from 'date-fns'

import { ref, watch, computed } from 'vue'
import { useContactStore } from '../stores/contactStore'

const props = defineProps({
  task: {
    type: Object,
    required: true
  },
  importSource: {
    type: String,
    required: true
  }
})

const showTaskSources = ['hubspot', 'pipedrive']
const showTaskType = computed(() => showTaskSources.includes(props.importSource))

const showTaskTimePicker = computed(() => props.importSource === 'hubspot')

const taskCopy = ref([])

taskCopy.value = JSON.parse(JSON.stringify(props.task))

watch(
  () => props.task,
  () => {
    taskCopy.value = JSON.parse(JSON.stringify(props.task))
  },
  { deep: true }
)

const contactStore = useContactStore()
function buildTaskType(taskId) {
  return contactStore.taskTypeList[props.importSource].find((task) => task.id === taskId)
}

function updateTaskDate(taskId, date) {
  updateTask(taskId, 'date', date)
}

function updateTaskType(taskId, type) {
  updateTask(taskId, 'type', type.id)
}

const emit = defineEmits(['updateTask'])

function toggleTaskStatus(task) {
  const newStatus = task.status === 'COMPLETED' ? 'NOT_STARTED' : 'COMPLETED'
  updateTask(task.id, 'status', newStatus)
}

function updateTask(taskId, taskField, taskValue) {
  const taskOldValue = props.task[taskField]
  if (taskField === 'date') {
    const taskValueDate = parseISO(taskValue.toISOString())
    const taskOldValueDate = parseISO(taskOldValue)

    if (!isEqual(taskValueDate, taskOldValueDate)) {
      emit('updateTask', taskId, taskField, taskValue.toISOString())
    }
  } else if (taskOldValue.toString() !== taskValue.toString()) {
    emit('updateTask', taskId, taskField, taskValue)
  }
}
</script>

<style scoped lang="scss">
.prospect__task {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  &--pinned {
    border: 1px solid #ababab;
  }
  .task__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .status-input {
      flex: 0 0 20px;
      //   width: 20px;
      height: 20px;
      border-radius: 50%;
      position: relative;
      margin-right: 8px;
      &:hover {
        cursor: pointer;
      }

      .checkmark-icon {
        stroke: #d2d2d2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .status-input--unchecked {
      border: 1.67px solid #d2d2d2;
    }

    .status-input--checked {
      border: 1.67px solid #0070ff;
      background: #0070ff;
      .checkmark-icon {
        stroke: #fff;
      }
    }
    .task__title--checked {
      text-decoration-line: line-through;
    }

    .custom-input--title {
      line-height: 1.6;
      font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;

      resize: vertical;

      font-size: 14px;
      color: #667085;
      border: 1px solid #fff;
      padding: 5px;
      border-radius: 4px;

      font-weight: 500;

      flex: 1;

      &:hover {
        border: 1px solid #dbdbdb;
      }

      &:focus {
        border: 1px solid #0070ff;
        outline: 2px solid #cbe2ff;
      }
    }

    .task__property {
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      .task__property-key {
        font-size: 12px;
        font-weight: 500;
        color: #667085;
      }
      .task__property-value {
        font-size: 12px;
        color: #344054;
      }
    }
  }

  .editor-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .editor-item__title {
      font-size: 12px;
      font-weight: 500;
      color: #667085;
      margin-bottom: 4px;
    }
    .editor-item__input {
      line-height: 1.6;
      font-family:
        Inter,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;

      resize: vertical;

      font-size: 14px;
      color: #667085;
      background: rgba(242, 241, 238, 0.6);
      border: 1px solid rgba(15, 15, 15, 0.1);
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #dbdbdb;
      font-weight: 500;

      &:focus {
        border: 1px solid #0070ff;
        outline: 2px solid #cbe2ff;
      }
    }

    .invalid:focus {
      border: 1px solid #f80000;
      outline: 2px solid #fecdcd;
    }

    .invalid {
      border: 1px solid #f80000;
    }
  }
}
.activity-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  .title {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    .pinned-badge {
      margin-left: 12px;
    }
  }
  .date {
    font-size: 12px;
    color: #667085;
  }
}
</style>
