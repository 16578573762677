<template>
  <div class="datatable--homemade">
    <table>
      <thead>
        <tr>
          <th class="data-table-header header--checkbox-column">
            <div class="header-icon-placeholder"></div>
          </th>
          <th class="data-table-header header--first-column">
            {{ headers[0].label }}
          </th>
          <th
            v-for="(header, index) in headers.filter(
              (header) => header.id !== 'dialStatus' && !header.hidden && !header.hideInTable
            )"
            :key="index"
            @click="sortBy(header.id)"
            class="data-table-header"
          >
            <div class="header__icon-container">
              {{ header.label }}
              <ArrowUpIcon
                v-if="sortField === header.id && sortOrder === 1"
                class="header__icon header__icon--active"
                size="16"
              />
              <ArrowDownIcon
                v-else-if="sortField === header.id && sortOrder === -1"
                class="header__icon header__icon--active"
                size="16"
              />
              <ArrowUpIcon v-else class="header__icon" size="16" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in filteredRows"
          :key="row.id"
          :class="rowClass(row)"
          style="display: flex; flex-direction: column"
        >
          <div>
            <td style="vertical-align: middle">
              <div class="icon-column">
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="18" />
                </div>
                <div
                  class="row-menu"
                  @click="($event) => rowMenuClicked($event, row)"
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                >
                  <ThreeDotIcon size="18" class="three-dot-icon" />
                </div>
              </div>
            </td>
            <td @click="openModal(row)">
              <div class="status-section cell cell--first-column clickable">
                <DialStatusBadge
                  :status="row.dialStatus"
                  :errorStatus="row.errorStatus"
                  :meeting-booked="row.meetingBooked"
                  :canceled-calls-count="row.canceledCallsCount"
                />
                <div class="call-count">
                  <CallIcon size="16" class="call-icon" />
                  {{ row.callCount }}
                </div>
              </div>
            </td>
            <td
              v-for="(header, index) in headers.filter(
                (header) => header.id !== 'dialStatus' && !header.hidden && !header.hideInTable
              )"
              :key="index"
              class="cell clickable"
              @click="openModal(row)"
            >
              <div v-if="header.id === 'status'">
                {{ formatLeadStatus(row[header.id]) }}
              </div>
              <div v-else-if="isUrl(row[header.id])" class="cell--link">
                <a @click.stop :href="row[header.id]" target="_blank"
                  >{{ row[header.id] }}<ExternalLinkIcon class="link-icon" size="14"
                /></a>
              </div>
              <div v-else>
                {{ formatPotentialDate(row[header.id]) ?? '-' }}
              </div>
            </td>
          </div>
          <div :class="rowClass(row)" class="transcription">
            <div class="transcription__text" v-if="row.transcription + row.tempTranscription">
              {{ row.transcription + row.tempTranscription ?? '' }}
            </div>
          </div>
        </tr>
      </tbody>
    </table>
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" class="prime-menu">
      <template #itemicon>
        <TrashIcon class="trash-icon" size="20" />
      </template>
    </Menu>
  </div>
</template>

<script setup>
import { usePhoneStore } from '@/stores/phone'
import { ref, computed } from 'vue'
import ArrowUpIcon from './icons/ArrowUpIcon.vue'
import ArrowDownIcon from './icons/ArrowDownIcon.vue'
import CallIcon from './icons/CallIcon.vue'
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import ThreeDotIcon from './icons/ThreeDotIcon.vue'

import DialStatusBadge from './DialStatusBadge.vue'

import Menu from 'primevue/menu'
import TrashIcon from './icons/TrashIcon.vue'
import { isUrl } from '@/helpers/url'
import { formatPotentialDate } from '@/helpers/date'
import { useContactStore } from '../stores/contactStore'

const sortOrder = ref()
const sortField = ref()

const contactStore = useContactStore()

function formatLeadStatus(status) {
  if (!contactStore.leadStatuses) {
    return status
  }
  return contactStore.leadStatuses.find((item) => item.id === status)?.label
}

function sortBy(field) {
  if (sortField.value === field) {
    sortOrder.value = sortOrder.value === 1 ? -1 : 1
  } else {
    sortField.value = field
    sortOrder.value = 1
  }

  customSort()
}

function customSort() {
  emit('sort:rows', { sortField: sortField.value, sortOrder: sortOrder.value })
}

const props = defineProps({
  rows: {
    type: Array,
    required: true
  },
  headers: {
    type: Array,
    required: true
  }
})

const filteredRows = computed(() => props.rows.filter((row) => !row.isDeleted))

const emit = defineEmits(['update:rows', 'openRowModal', 'deleteContact', 'sort:rows'])

const menu = ref()
const items = ref([
  {
    label: 'Delete',
    command: deleteContactFromList
  }
])

function deleteContactFromList() {
  emit('deleteContact', selectedRows.value)
}

const phoneStore = usePhoneStore()

function rowMenuClicked($events, row) {
  selectedRows.value = row
  menu.value.toggle($events)
}

function openModal(row) {
  emit('openRowModal', { data: row })
}

const selectedRows = ref([])

const rowClass = (row) => {
  return [
    {
      'row row--default':
        row.dialStatus === 'pending' ||
        row.dialStatus === 'canceled' ||
        row.dialStatus === 'voicemail' ||
        row.dialStatus === 'busy' ||
        row.dialStatus === 'no-answer',
      'row row--in-progress': row.dialStatus === 'in-progress',
      'row row--ringing': row.dialStatus === 'ringing' || row.dialStatus === 'initiated',
      'row row--completed':
        row.dialStatus === 'completed' ||
        row.dialStatus === 'connected' ||
        row.dialStatus === 'left-voicemail' ||
        row.dialStatus === 'failed' ||
        row.errorStatus === 'not-authorized' ||
        row.errorStatus === 'no number' ||
        row.errorStatus === 'invalid number' ||
        row.errorStatus === 'country mismatch',
      'row row--meeting-booked':
        row.meetingBooked &&
        row.dialStatus !== 'in-progress' &&
        row.dialStatus !== 'ringing' &&
        row.dialStatus !== 'initiated'
    }
  ]
}
</script>

<style lang="scss">
.datatable--homemade {
  width: 100%;
  height: 100%;
  display: flex;

  table {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    flex: 1 1 auto;
    padding-bottom: 12px;
    border-collapse: collapse;
    white-space: nowrap;
    overflow: scroll;
    border-radius: 0 0 8px 8px;
  }

  tbody {
    display: table;
    tr {
      border-bottom: 1px solid #e2e8f0;
    }
  }

  th {
    cursor: pointer;
    padding: 10px;
    text-align: left;
  }
  thead {
    position: sticky;
    top: 0;
    background-color: #fcfcfd;
    tr {
      border-bottom: 1px solid #e2e8f0;
    }
  }

  td {
    padding: 0;
  }

  .data-table-header {
    color: #667085;
    font-weight: 500;
    font-size: 12px;
    user-select: none;
    padding: 0.75rem 1rem;
    min-width: 195px;
    &:last-child {
      width: 100%;
    }
  }

  .cell {
    color: #667085;
    font-size: 14px;
    vertical-align: middle;
    padding: 12px;
    min-width: 195px;
    max-width: 195px;
    overflow: hidden;
  }

  .header--first-column,
  .cell--first-column {
    padding-left: 0;
    min-width: 215px;
  }

  .clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .cell--link {
    a {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 4px;
      transition: 0.05s ease;

      .link-icon {
        margin-left: 6px;
      }
    }
  }

  .header--checkbox-column {
    padding: 12px 0 12px 40px;
    min-width: unset;
  }

  .header__icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header__icon {
      stroke: #dbdbdb;
      &--active {
        stroke: #667085;
      }
    }
  }

  .checkbox-checkmark {
    stroke: #fff;
  }

  .row {
    background: #fff;
  }

  .row--ringing {
    background: #fdf8e1;
    transition: background 0.27s ease;
  }

  .row--in-progress {
    background: #ecfdf3;
    transition: background 0.27s ease;
  }

  .row--completed {
    background: #eef1f4;
    transition: background 0.27s ease;
  }

  .row--meeting-booked {
    background: #f9eeff;
    transition: background 0.27s ease;
  }

  .target-icon {
    fill: #9e00ff;
  }

  .icon-column {
    display: flex;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;

    // like cell
    color: #667085;
    font-size: 14px;
    vertical-align: middle;
  }

  .link-icon {
    stroke: #787878;
    transition: all 1s ease;
  }

  tbody tr:hover .link-icon-container {
    opacity: 1;
    display: flex;
  }

  tbody tr:hover .row-menu {
    display: none;
  }

  tbody td:hover ~ td .link-icon-container {
    display: none;
  }

  tbody tr:hover .target-icon {
    display: none;
  }
  tbody td:hover ~ td .target-icon {
    display: inline-block;
  }

  .link-icon-container {
    padding: 4px;
    display: flex;
    align-items: center;

    display: none;
    opacity: 0;
  }

  .row-menu {
    transition: 0.05s ease;
    padding: 4px;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);

      cursor: pointer;
    }

    .three-dot-icon {
      fill: #787878;
    }
  }

  tbody .row td {
    border: none;
  }

  .transcription {
    padding-left: 60px;
    font-size: 14px;
    font-weight: 500;
    color: #344054;

    &__text {
      padding-bottom: 8px;
      text-wrap: wrap;
    }
  }

  tbody tr td:hover:first-child .row-menu {
    display: flex;
  }

  tbody tr td:hover:first-child .link-icon-container {
    display: none;
  }

  .datatable-wrapper {
    border-radius: 0 0 8px 8px;
  }

  .checkbox-column {
    padding: 12px 0 12px 16px;
  }

  .header-icon-placeholder {
    height: 20px;
    width: 20px;
  }
  .status-section {
    display: flex;
    justify-content: space-between;
    .call-count {
      margin-right: 20px;
      color: #787878;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      .call-icon {
        stroke: #787878;
        margin-right: 6px;
      }
    }
  }
}
</style>
