<template>
  <div>
    <div v-for="item in dataCopyFiltered" :key="item.id" class="modal__body-item">
      <div class="item__title">{{ item.label }}</div>
      <PrimeDropdown
        v-if="item.id === 'ownerId'"
        v-model="item.newValue"
        :options="contactStore.contactOwners"
        optionLabel="name"
        optionValue="id"
        placeholder="Select an owner"
        @update:modelValue="($events) => updateContactOwner($events, { value: item.value })"
        filter
        autoFilterFocus
        resetFilterOnHide
        filterPlaceholder="Search"
        @show="setDropdownFlag(true)"
        @hide="setDropdownFlag(false)"
      >
        <template #option="slotProps">
          <div>
            <div>{{ slotProps.option.name }}</div>
            <div v-if="slotProps.option.email" class="owner-dropdown__email">
              {{ slotProps.option.email }}
            </div>
          </div>
        </template>
      </PrimeDropdown>

      <dropdown
        v-else-if="item.id === 'status' && contactStore.leadStatuses"
        :items="leadStatuses"
        :initial-value="initialLeadStatus(item.value)"
        @update:value="($events) => updateLeadStatus($events, { value: item.value })"
      />
      <textarea
        v-else-if="item.id === 'description'"
        class="custom-input valid"
        v-model="item.newValue"
        @blur="saveNewValue(item)"
        rows="5"
      >
      </textarea>
      <div class="non-editable-field non-editable-field--link" v-else-if="item.id === 'company'">
        <a :href="dataCopy.find((item) => item.id === 'companyUrl').value" target="_blank"
          >{{ `${item.value}` }}<ExternalLinkIcon class="external-link-icon" size="16"
        /></a>
      </div>
      <div
        class="non-editable-field non-editable-field--link"
        v-else-if="item.id === 'dealName' && item.value"
      >
        <a :href="dataCopy.find((item) => item.id === 'dealUrl').value" target="_blank"
          >{{ `${item.value}` }}<ExternalLinkIcon class="external-link-icon" size="16"
        /></a>
      </div>
      <div v-else-if="item.nonEditable && !isUrl(item.value)" class="non-editable-field">
        {{ formatPotentialDate(item.value) || '-' }}
      </div>
      <div
        v-else-if="item.nonEditable && isUrl(item.value)"
        class="non-editable-field non-editable-field--link"
      >
        <a :href="item.value" target="_blank"
          >{{ item.value }}<ExternalLinkIcon class="external-link-icon" size="16"
        /></a>
      </div>
      <input
        v-else-if="!item.hidden"
        class="custom-input"
        v-model.trim="item.newValue"
        @blur="saveNewValue(item)"
        :class="{
          invalid: !isInputValid(item.id, item.newValue),
          valid: isInputValid(item.id, item.newValue)
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { usePhoneStore } from '@/stores/phone'
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import Dropdown from './Dropdown.vue'
import { isUrl } from '../helpers/url'
import { formatPotentialDate } from '../helpers/date'
import { useContactStore } from '../stores/contactStore'
import PrimeDropdown from 'primevue/dropdown'

function setDropdownFlag(bool) {
  phoneStore.dropdownInModalOpen = bool
}

const phoneStore = usePhoneStore()

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
})

const contactStore = useContactStore()

const leadStatuses = computed(() => contactStore.leadStatuses)
const initialLeadStatus = (value) =>
  contactStore.leadStatuses?.find((status) => status.id === value)

function updateLeadStatus(status, { value }) {
  saveNewValue({ id: 'status', value, newValue: status.id, label: 'Status' })
}

function updateContactOwner(newValue, { value }) {
  saveNewValue({ id: 'ownerId', value, newValue, label: 'Contact owner' })
}

const emit = defineEmits(['saveNewValue'])

const dataCopy = ref()

dataCopy.value = JSON.parse(JSON.stringify(props.data)).map((item) => ({
  ...item,
  newValue: item.value
}))

const dataCopyFiltered = computed(() => {
  return dataCopy.value.filter((item) => !item.hidden && !item.hideInModal)
})

watch(
  () => props.data,
  (newValue) => {
    if (newValue) {
      dataCopy.value = JSON.parse(JSON.stringify(newValue)).map((item) => ({
        ...item,
        newValue: item.value
      }))
    }
  }
)

const maxLengths = {
  firstName: 255,
  lastName: 255,
  title: 255,
  company: 255,
  phoneNumber: 30,
  email: 255,
  ownerId: 255
}

function isInputValid(id, value) {
  if (!maxLengths[id]) {
    return true
  }
  const isSizeValid = !value || value.length <= maxLengths[id]
  return isSizeValid
}

function saveNewValue({ id, value, newValue, label }) {
  if (value === newValue) {
    return
  }

  const isSizeValid = isInputValid(id, newValue)

  if (!isSizeValid) {
    phoneStore.errorMessage = `Value is too large for the field: ${label.toLowerCase()}`
    return
  }

  emit('saveNewValue', { id, label }, newValue)
}
</script>

<style lang="scss">
.owner-dropdown__email {
  color: #667085;
  margin-top: 8px;
  font-size: 12px;
}

.modal__body-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .item__title {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
.custom-input {
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

  resize: vertical;

  font-size: 14px;
  color: #667085;
  background: rgba(242, 241, 238, 0.6);
  border: 1px solid rgba(15, 15, 15, 0.1);
  padding: 5px;
  border-radius: 4px;

  border: 1px solid #dbdbdb;
  font-weight: 500;

  &--disabled {
    cursor: not-allowed;
  }
}

.valid:focus {
  border: 1px solid #0070ff;
  outline: 2px solid #cbe2ff;
}

.invalid:focus {
  border: 1px solid #f80000;
  outline: 2px solid #fecdcd;
}

.invalid {
  border: 1px solid #f80000;
}

/* Target the tooltip content */
.p-tooltip .p-tooltip-text {
  border-radius: 8px; /* Adjust border-radius */
  font-size: 12px; /* Change font size */
  padding: 10px; /* Adjust padding if needed */
}

/* Custom transition */
.p-tooltip {
  transition: opacity 0.02s ease-in-out; /* Customize the transition effect */
  opacity: 0;
}

.non-editable-field {
  font-weight: 500;
  color: #787878;
  display: flex;

  &--link {
    margin-left: -6px;

    a {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 4px;
      transition: 0.05s ease;
    }

    .external-link-icon {
      stroke: #787878;
      margin-left: 6px;
    }
  }
}
</style>
