<template>
  <div
    class="prospect__call-log"
    :class="{
      'prospect__call-log--pinned': callLog.pinned,
      'prospect__call-log--active': callLog.onGoingCall
    }"
  >
    <div class="log__header">
      <div v-if="callLog.onGoingCall" class="log__title">
        Call - Connected
        <div class="in-progress-badge">In progress</div>
      </div>
      <div class="log__title" v-else-if="isPipedrive">
        {{ callLog.subject }}
      </div>
      <div class="log__title" v-else>
        {{ `Call - ${buildCallDispositionValue(callLog)?.label}` }}
        <pinned-badge v-if="callLog.pinned" class="pinned-badge" />
      </div>
      <div class="log__date">{{ formatDate(callLog.date) }}</div>
    </div>
    <div class="log__body" v-if="!callLog.onGoingCall">
      <div
        class="log__body-item log__body-item--outcome"
        v-if="!isPipedrive || callLog.callDisposition"
      >
        <div class="log__body-item-key">Outcome</div>
        <dropdown
          class="log__body-item-value"
          :items="contactStore.callDispositionList[callLog.importSource]"
          :initial-value="buildCallDispositionValue(callLog)"
          @update:value="($events) => updateCallDisposition(callLog, $events)"
        />
      </div>
      <div v-if="!isPipedrive" class="log__body-item log__body-item--direction">
        <div class="log__body-item-key">Direction</div>
        <div class="log__body-item-value">{{ callLog.direction }}</div>
      </div>
      <div
        class="log__body-item log__body-item--duration"
        v-if="typeof callLog.duration === 'number'"
      >
        <div class="log__body-item-key">Duration</div>
        <div class="log__body-item-value">{{ formatCallDuration(callLog.duration) }}</div>
      </div>
    </div>
    <div class="recording">
      <audio
        v-if="hasRecording"
        :id="`player_${callLog.callSid}`"
        controls
        @play="loadRecording(callLog)"
      >
        <source v-if="callLog.audioUrl" :src="callLog.audioUrl" type="audio/mp3" />
      </audio>
    </div>
    <Textarea
      v-model="callLogCopy.body"
      autoResize
      rows="2"
      @blur="saveLog(callLog.id, callLogCopy.body)"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { formatDate } from '../helpers/date'
import Dropdown from './Dropdown.vue'
import PinnedBadge from './PinnedBadge.vue'
import Textarea from 'primevue/textarea'
import Plyr from 'plyr'

import { ref, watch, computed, nextTick, onMounted } from 'vue'
import { useContactStore } from '../stores/contactStore'
import axiosInstance from '@/services/axiosService'

const hasRecording = computed(
  () =>
    !props.callLog.noRecording && props.callLog.direction === 'OUTBOUND' && props.callLog.callSid
)

const props = defineProps({
  callLog: {
    type: Object,
    required: true
  }
})

const isPipedrive = computed(() => props.callLog.importSource === 'pipedrive')

const contactStore = useContactStore()

const callLogCopy = ref([])

callLogCopy.value = JSON.parse(JSON.stringify(props.callLog))

let player

onMounted(() => {
  nextTick(() => {
    player = new Plyr(`#player_${props.callLog.callSid}`, {
      controls: ['play-large', 'play', 'progress', 'duration', 'download']
    })
  })
})

async function loadRecording(call) {
  const callLog = props.callLog
  try {
    if (!callLog.audioUrl && !callLog.recordingLoading) {
      callLog.recordingLoading = true

      const response = await axiosInstance.get(`/api/call-logs/recording/${call.callSid}`, {
        responseType: 'blob'
      })
      if (response.data) {
        // Create a URL from the blob
        const url = URL.createObjectURL(response.data)
        callLog.audioUrl = url
      }
      if (!player.source) {
        player.source = {
          type: 'audio',
          title: 'Recording',
          sources: [
            {
              src: callLog.audioUrl,
              type: 'audio/mpeg'
            }
          ]
        }
        player.play()
        const downloadButton = player.elements.buttons.download
        if (downloadButton) {
          downloadButton.setAttribute('download', `${callLog.prospectName}.mp3`)
        }
      }
    } else if (callLog.audioUrl) {
      player.play()
    }
  } catch (error) {
    console.error('Failed to load recording:', error)
    callLog.noRecording = true
  } finally {
    callLog.recordingLoading = false
  }
}

watch(
  () => props.callLog,
  () => {
    callLogCopy.value = JSON.parse(JSON.stringify(props.callLog))
  },
  { deep: true }
)

function buildCallDispositionValue(log) {
  return contactStore.callDispositionList[log.importSource].find(
    (item) => item.id === log.callDisposition
  )
}

function updateCallDisposition(log, newValue) {
  emit('saveCallDisposition', log.id, newValue.id)
}

const emit = defineEmits(['saveLog', 'saveCallDisposition'])

function saveLog(id, newLog) {
  if (props.callLog.body !== newLog) {
    emit('saveLog', id, newLog)
  }
}

function formatCallDuration(durationInSeconds) {
  const minutes = Math.floor(durationInSeconds / 60)
  const seconds = durationInSeconds % 60

  const formattedMinutes = minutes.toString().padStart(1, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}
</script>

<style scoped lang="scss">
.prospect__call-log {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  .recording {
    margin-bottom: 16px;
  }
  &--pinned {
    border: 1px solid #ababab;
  }
  &--active {
    border: 1px solid #14ba6d;
  }
  .log__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .log__title {
      font-size: 14px;
      font-weight: 500;
      display: flex;

      .pinned-badge {
        margin-left: 12px;
      }
    }
    .log__date {
      font-size: 12px;
      color: #667085;
    }
  }

  .log__body {
    display: flex;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .log__body-item {
    display: flex;
    flex-direction: column;
    &--outcome {
      margin-right: 24px;
    }
    &--direction {
      margin-right: 24px;
    }

    .log__body-item-key {
      font-size: 12px;
      font-weight: 500;
      color: #667085;
      margin-bottom: 4px;
    }
    .log__body-item-value {
      font-size: 12px;
      color: #667085;
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
}
.in-progress-badge {
  margin-left: 12px;
  display: flex;
  align-items: center;
  background-color: #14ba6d;
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 4px;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
}
</style>
