<template>
  <div class="phone-number-setting">
    <div class="phone-number__text">Number</div>
    <div ref="phoneNumberDropdown" class="phone-number-dropdown">
      <div
        @click="toggleDropdown"
        class="dropdown__button"
        :class="{
          'dropdown__button--enable': !disable,
          'dropdown__button--disable': disable
        }"
      >
        <div class="dropdown__buttonText" v-if="sessionStore.fromPhoneNumber">
          {{ formatPhoneNumber(sessionStore.fromPhoneNumber.phoneNumber) }}
        </div>
        <div v-else>No phone number</div>
        <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" color="#D0D5DD" size="12" />
        <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
      </div>
      <div
        v-if="isOpen && sessionStore.fromPhoneNumberList.length"
        class="dropdown--open"
        @click.stop=""
      >
        <div class="dropdown__main">
          <div
            class="dropdown__item"
            v-for="phoneNumberItem in sessionStore.fromPhoneNumberList"
            :key="phoneNumberItem.id"
            @click="selectPhoneNumber(phoneNumberItem)"
          >
            <div
              class="dropdown__item-group"
              :class="{
                'dropdown__item--selected': phoneNumberItem.id === sessionStore.fromPhoneNumber.id,
                'dropdown__item--unselected': phoneNumberItem.id !== sessionStore.fromPhoneNumber.id
              }"
            >
              <div
                v-if="phoneNumberItem.id === sessionStore.fromPhoneNumber.id"
                class="checkmark-icon"
              >
                <CheckmarkIcon size="21" />
              </div>
              <div class="dropdown__item-text">
                {{ formatPhoneNumber(phoneNumberItem.phoneNumber) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import { onMounted, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { formatPhoneNumber } from '../helpers/phoneNumber'
import { useSessionStore } from '@/stores/sessionStore'

const sessionStore = useSessionStore()

const props = defineProps({
  disable: {
    required: true,
    type: Boolean
  }
})

onMounted(() => sessionStore.fetchPhoneNumbers())

const isOpen = ref(false)

function toggleDropdown() {
  if (props.disable) {
    return
  }
  isOpen.value = !isOpen.value
}

const phoneNumberDropdown = ref(null)

onClickOutside(phoneNumberDropdown, () => {
  closeDropdown()
})

function closeDropdown() {
  isOpen.value = false
}

function selectPhoneNumber(phoneNumberItem) {
  sessionStore.fromPhoneNumber = phoneNumberItem
  isOpen.value = false
  sessionStore.updateSessionSettings({ fromPhoneNumberId: phoneNumberItem.id })
}
</script>

<style scoped>
.phone-number-setting {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.phone-number__text {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
}
.phone-number-dropdown {
  position: relative;
  display: inline-block;
  background: #fff;
}

.phone-number-dropdown .dropdown__button {
  padding: 6px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-weight: 500;
  width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.4s ease;
}

.phone-number-dropdown .dropdown__button--enable {
  color: #344054;
  background: #fff;
}
.phone-number-dropdown .dropdown__button--enable:hover {
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
.phone-number-dropdown .dropdown__button--disable {
  color: #b8b8b8;
  background: #f6f6f6;
  user-select: none;
}

.dropdown__buttonText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.dropdown--open {
  min-width: 100%;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #344054;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  overflow: visible;
  background: #fff;
  z-index: 2;
}

.dropdown__main {
  padding: 4px;
}

.dropdown__item-group {
  display: flex;
  align-items: center;
}
.dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.05s ease;
  padding: 4px 4px 4px 0;
}
.dropdown__item-text {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__item:hover {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.07);
  opacity: 1;
  cursor: pointer;
}
.checkmark-icon {
  display: flex;
}
.dropdown__item--selected {
  padding-left: 0;
  font-weight: 600;
}
.dropdown__item--unselected {
  padding-left: 21px;
}
.dropdown__button-svg {
  pointer-events: none;
}
</style>
