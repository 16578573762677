<template>
  <Modal :is-modal-open="isModalOpen" @closeModal="closeModal">
    <div class="connections">
      <div class="title">Connections</div>
      <!-- <div @click="phoneStore.deleteAllActivities">Delete all activities</div> -->
      <div class="card-container">
        <div
          class="card"
          :class="{
            'card-on-hover': connection.isActive && !connection.isConnected
          }"
          v-for="connection in connections"
          :key="connection.name"
          @click="connect(connection)"
        >
          <div class="card__header">
            <div>
              <img class="card__logo" :src="connection.logo" :alt="connection.name + ' logo'" />
            </div>
            <div v-if="connection.isActive">
              <div v-if="!connection.isConnected" class="card__button">Connect</div>
              <div v-else class="card__status">
                <checked-contained-icon
                  class="card__status-logo"
                  size="20"
                ></checked-contained-icon>
                <div>Connected</div>
              </div>
            </div>
            <div v-else>
              <div class="badge__soon">Soon</div>
            </div>
          </div>
          <!-- <div class="card__title">
            {{ connection.name }}
          </div> -->
          <div class="card__subtitle">
            <CheckmarkIcon class="checkmark-icon" size="21" />Import leads
          </div>
          <div class="card__subtitle">
            <CheckmarkIcon class="checkmark-icon" size="21" />Edit lead detail
          </div>
          <div class="card__subtitle">
            <CheckmarkIcon class="checkmark-icon" size="21" />Automatically log calls
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import Modal from './Modal.vue'
import { ref, onMounted } from 'vue'
import salesforceLogo from '@/assets/salesforce-logo.png'
import outreachLogo from '@/assets/outreach-logo.png'
import hubspotLogo from '@/assets/hubspot-logo.png'
import pipedriveLogo from '@/assets/pipedrive-logo.png'
import CheckedContainedIcon from './icons/CheckedContainedIcon.vue'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import { useSalesforceStore } from '../stores/salesforceStore'
import { useHubspotStore } from '../stores/hubspotStore'
import { usePhoneStore } from '../stores/phone'
import { useRoute, useRouter } from 'vue-router'
import { usePipedriveStore } from '@/stores/pipedriveStore'

const salesforceStore = useSalesforceStore()
const hubspotStore = useHubspotStore()
const pipedriveStore = usePipedriveStore()

const phoneStore = usePhoneStore()

const emit = defineEmits(['closeModal'])

function closeModal() {
  emit('closeModal')
}

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    required: true
  }
})

const connections = ref([
  { name: 'Salesforce', logo: salesforceLogo, isConnected: false, isActive: true },
  { name: 'Hubspot', logo: hubspotLogo, isConnected: false, isActive: true },
  { name: 'Pipedrive', logo: pipedriveLogo, isConnected: false, isActive: true }
])

onMounted(async () => {
  const route = useRoute()
  const router = useRouter()
  await router.isReady()

  if (route.query.source === 'salesforce' && route.query.success === 'true') {
    salesforceStore.setConnected(true)
  }

  if (route.query.source === 'hubspot' && route.query.success === 'true') {
    hubspotStore.setConnected(true)
  }

  if (route.query.source === 'pipedrive' && route.query.success === 'true') {
    pipedriveStore.setConnected(true)
  }

  connections.value.find((conn) => conn.name === 'Salesforce').isConnected =
    salesforceStore.isConnected
  connections.value.find((conn) => conn.name === 'Hubspot').isConnected = hubspotStore.isConnected
  connections.value.find((conn) => conn.name === 'Pipedrive').isConnected =
    pipedriveStore.isConnected
})

onMounted(() => {
  // pre load logo to avoid the blinking effect
  connections.value.forEach(({ logo }) => {
    const image = new Image()
    image.src = logo
  })
})

async function connect(connection) {
  if (!connection.isActive || connection.isConnected) {
    return
  }

  let authUrl
  if (connection.name === 'Salesforce') {
    authUrl = await salesforceStore.getAuthUrl()
  } else if (connection.name === 'Hubspot') {
    authUrl = await hubspotStore.getAuthUrl()
  } else if (connection.name === 'Pipedrive') {
    authUrl = await pipedriveStore.getAuthUrl()
  }

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get authorization URL')
    // Handle the error case
  }
  // connection.isConnected = true
  // Here you would also handle the actual connection logic, API calls, etc.
}
</script>

<style scoped>
.connections {
  padding: 36px 60px;
}

.title {
  color: #212223;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
  margin-bottom: 30px;
}

.card-container {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.card {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card-on-hover:hover {
  cursor: pointer;
  border: 1px solid #c2c2c2;
}
.card__header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-height: 50px;
}
.card__logo {
  width: 80px;
}

.card__button {
  color: #0070ff;
  font-size: 14px;
  font-weight: 500;
}

.card__title {
  color: #2b2c2e;
  font-size: 18px;
  font-weight: 500;
}

.card__subtitle {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.card__status {
  display: flex;
  align-items: center;
  color: #14ba6d;
  font-size: 14px;
  font-weight: 500;
}
.card__status-logo {
  margin-right: 4px;
}

.checkmark-icon {
  stroke: #7e7e7e;
  margin-right: 4px;
}
.badge__soon {
  padding: 2px 6px;
  border-radius: 4px;
  color: rgba(55, 53, 47, 0.65);
  background: rgba(206, 205, 202, 0.5);
  font-size: 9px;
}
</style>
