<template>
  <div class="analytics-list">
    <div class="filter">
      <div class="filter-container">
        <MultiSelect
          v-model="analyticsStore.selectedUsers"
          placeholder="Select users"
          :options="userOptions"
          optionLabel="label"
          optionValue="value"
          @update:model-value="onUserFilterChange"
        />
        <analytics-date-range-selector
          @update:value="updateDateValue"
          :initial-value="analyticsStore.optionName"
          :initial-custom-start-date="analyticsStore.startDate"
          :initial-custom-end-date="analyticsStore.endDate"
        />
      </div>

      <empty-dropdown class="setting-dropdown" alignRight dropdownTitle="Settings">
        <div class="setting-dropdown--open">
          <div class="setting-item">
            <div class="setting__text">Conversation threshold</div>
            <div class="setting__switch">
              <dropdown
                class="duration-dropdown"
                :items="conversationThresholdOptions"
                :initial-value="
                  conversationThresholdOptions.find(
                    (item) => item.id === sessionStore.conversationThreshold
                  )
                "
                @update:value="onConversationThresholdChange"
              >
                <template #inputText="{ label }">
                  <SuperiorIcon size="10" class="superior-icon" />
                  {{ label }}
                </template>
                <template #item="{ label }">
                  <SuperiorIcon size="10" class="superior-icon" />
                  {{ label }}
                </template>
              </dropdown>
            </div>
          </div>
        </div>
      </empty-dropdown>
    </div>
    <div class="session-analytics-overview">
      <div class="card" v-for="metric in overviewMetrics" :key="metric.title">
        <div class="card-title">{{ metric.title }}</div>
        <div class="card-content">{{ metric.value }}</div>
      </div>
    </div>
    <div class="session-analytics-table">
      <div v-if="userAnalyticsFiltered.length" class="prime-table">
        <DataTable
          :value="userAnalyticsFiltered"
          style="width: 100%"
          scrollable
          scrollHeight="flex"
          tableStyle="padding-bottom: 12px"
        >
          <Column
            field="userName"
            header="User"
            headerClass="data-table-header"
            bodyClass="cell cell-first-column"
            style="min-width: 195px"
            frozen
          >
          </Column>
          <Column
            field="totalCalls"
            header="Dials"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="openAnalyticsDetails({ userId: slotProps.data.userId, logType: 'dials' })"
              >
                {{ slotProps.data.totalCalls }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="callsPerDay"
            header="Dials / day"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectedCalls"
            header="Connects"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="
                  openAnalyticsDetails({ userId: slotProps.data.userId, logType: 'connects' })
                "
              >
                {{ slotProps.data.connectedCalls }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="connectRate"
            header="% Connect"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="callbacks"
            header="Callbacks"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="
                  openAnalyticsDetails({ userId: slotProps.data.userId, logType: 'callbacks' })
                "
              >
                {{ slotProps.data.callbacks }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="callbackRate"
            header="% Callback"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectedCallback"
            header="Callback connects"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="
                  openAnalyticsDetails({
                    userId: slotProps.data.userId,
                    logType: 'callbackConnects'
                  })
                "
              >
                {{ slotProps.data.connectedCallback }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="connectedCallbackRate"
            header="% Callback connect"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="conversations"
            header="Conversations"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="
                  openAnalyticsDetails({ userId: slotProps.data.userId, logType: 'conversations' })
                "
              >
                {{ slotProps.data.conversations }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="conversationRate"
            header="% Conversation"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="meetingBookedCount"
            header="Meetings"
            headerClass="data-table-header"
            bodyClass="cell cell--clickable"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
            <template #body="slotProps">
              <div
                class="cell--link"
                @click="
                  openAnalyticsDetails({ userId: slotProps.data.userId, logType: 'meetingBooked' })
                "
              >
                {{ slotProps.data.meetingBookedCount }}
                <div class="link-icon-container">
                  <ExternalLinkIcon class="link-icon" size="14" />
                </div>
              </div>
            </template>
          </Column>
          <Column
            field="meetingBookedRate"
            header="% Meeting"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="talkTime"
            header="Talk time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="averageTalkTime"
            header="Avg talk time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="sessionTime"
            header="Session time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>

          <ColumnGroup type="footer">
            <Row>
              <Column class="column--total" footer="Total" frozen />
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.totalCalls }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callsPerDay }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCalls }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callbacks }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callbackRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCallback }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCallbackRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.conversations }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.conversationRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.meetingBookedCount }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.meetingBookedRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.talkTime }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.averageTalkTime }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.totalSessionTime }}</template>
              </Column>
            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
      <div v-else class="table__row-group--empty">
        <Loader></Loader>
      </div>
    </div>
    <div class="heatmap-container">
      <div class="heatmap-title">Connect rate heatmap</div>
      <connect-rate-heatmap :heatmap-data="heatmapData" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import ConnectRateHeatmap from './components/ConnectRateHeatmap.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Row from 'primevue/row'
import ColumnGroup from 'primevue/columngroup'
import Loader from './components/Loader.vue'
import Dropdown from './components/Dropdown.vue'
import ExternalLinkIcon from './components/icons/ExternalLinkIcon.vue'
import SuperiorIcon from './components/icons/SuperiorIcon.vue'
import axios from './services/axiosService'
import { useAuthStore } from './stores/auth'
import { useSessionStore } from './stores/sessionStore'
import MultiSelect from './components/Multiselect.vue'
import EmptyDropdown from './components/EmptyDropdown.vue'
import AnalyticsDateRangeSelector from './components/AnalyticsDateRangeSelector.vue'
import SortIcon from './components/SortIcon.vue'
import { useRouter } from 'vue-router'
import { useAnalyticsStore } from './stores/analyticsStore'

const authStore = useAuthStore()

const heatmapData = ref([])

const fetchHeatmapData = async () => {
  try {
    const response = await axios.post('/api/analytics/connect-rate-heatmap', {
      startDate: analyticsStore.startDate,
      endDate: analyticsStore.endDate,
      selectedUserIds: analyticsStore.selectedUsers.map((i) => i.value)
    })
    heatmapData.value = response.data
  } catch (error) {
    console.error('Error fetching heatmap data:', error)
  }
}

const overviewMetrics = computed(() => {
  return [
    { title: 'Dials', value: total.value.totalCalls },
    { title: 'Connects', value: total.value.connectedCalls },
    { title: 'Connect rate', value: total.value.connectRate },
    { title: 'Conversations', value: total.value.conversations },
    { title: 'Meeting booked', value: total.value.meetingBookedCount }
  ]
})
const router = useRouter()

const analyticsStore = useAnalyticsStore()

function openAnalyticsDetails({ userId, logType }) {
  router.push({
    name: 'analytics-details',
    params: {
      userId,
      logType
    }
  })
}

const conversationThresholdOptions = [
  {
    id: 0,
    label: '0 seconds'
  },
  {
    id: 30,
    label: '30 seconds'
  },
  {
    id: 60,
    label: '60 seconds'
  },
  {
    id: 90,
    label: '90 seconds'
  },
  {
    id: 120,
    label: '120 seconds'
  }
]

const sessionStore = useSessionStore()

async function onConversationThresholdChange({ id }) {
  await sessionStore.updateConversationThreshold(id)
  fetchAnalytics()
}

const userOptions = ref([])

const onUserFilterChange = () => {
  fetchAnalytics()
  fetchHeatmapData()
}

const userAnalyticsFiltered = ref([])

function updateDateValue(updatedDate) {
  analyticsStore.startDate = updatedDate.startDate
  analyticsStore.endDate = updatedDate.endDate
  analyticsStore.optionName = updatedDate.optionName
  fetchAnalytics()
  fetchHeatmapData()
}

const userAnalytics = ref([])
const total = ref([])

async function fetchAnalytics() {
  try {
    const response = await axios.post(`/api/analytics/company/${authStore.userCompanyId}`, {
      startDate: analyticsStore.startDate,
      endDate: analyticsStore.endDate,
      conversationThreshold: sessionStore.conversationThreshold,
      selectedUserIds: analyticsStore.selectedUsers.map((i) => i.value)
    })
    userAnalytics.value = response.data.analytics
    userAnalyticsFiltered.value = response.data.analytics
    total.value = response.data.total
    userOptions.value = response.data.allCompanyUsers
  } catch (error) {
    console.error('Failed to fetch analytics:', error)
  }
}
</script>

<style lang="scss">
.analytics-list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  .cell--clickable:hover .link-icon {
    opacity: 1;
    display: flex;
  }

  tbody td:hover ~ td .link-icon {
    display: none;
  }

  .link-icon-container {
    padding: 4px;
    display: flex;
    align-items: center;
    min-width: 22px;
  }

  .link-icon {
    display: none;
    opacity: 0;
    stroke: #787878;
    transition: all 1s ease;
  }

  .superior-icon {
    fill: #344054;
  }
  .setting-dropdown {
    align-self: flex-end;
    &--open {
      display: flex;
      flex-wrap: wrap;
    }
    .setting-item {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      font-weight: 500;
      font-size: 14px;
      padding: 8px;
      line-height: 1.4;
      min-width: 230px;
      .setting__text {
        white-space: nowrap;
        margin-right: 24px;
      }

      .setting__switch {
        display: flex;
        align-items: center;
      }
    }
  }

  .filter {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;

    .filter-container {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }

    .duration-filter {
      display: flex;
      flex-direction: column;
      color: #667085;
      font-weight: 500;
      font-size: 12px;
      .label {
        margin-bottom: 4px;
        text-wrap: wrap;
      }
      .duration-dropdown {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .session-analytics-overview {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    .card {
      flex: 1;
      border: 1px solid #eaecf0;
      border-radius: 8px;
      padding: 12px;
      .card-title {
        font-weight: 500;
        color: #667085;
      }
      .card-content {
        font-size: 30px;
      }
    }
  }

  .session-analytics-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    align-items: start;

    border: 1px solid #eaecf0;
    border-radius: 8px;

    .cell {
      &--clickable {
        display: flex;
        cursor: pointer;
        &:hover {
          background: rgba(55, 53, 47, 0.08);
          cursor: pointer;
        }
      }
    }

    .prime-table {
      min-height: 0;
      flex: 1 1 auto;
      width: 100%;
      border-radius: 8px;
    }

    .table__row-group--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
    }
    .p-datatable-table-container {
      border-radius: 8px;
    }
  }

  .column--total {
    font-size: 14px;
    font-weight: 600;

    border: none;
    padding-bottom: 16px;
  }

  .cell--link {
    padding: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .heatmap-container {
    margin-bottom: 40px;

    .heatmap-title {
      font-size: 16px;
      font-weight: 600;
      color: #344054;
      margin-bottom: 8px;
    }
  }
}
</style>
