import { parseISO, isValid } from 'date-fns'

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
}

const optionsNoTime = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

export const formatDate = (date) => new Intl.DateTimeFormat('fr-FR', options).format(new Date(date))

export const formatDateWithoutTime = (date) =>
  new Intl.DateTimeFormat('fr-FR', optionsNoTime).format(new Date(date))

export function sortByDescendingDate(arrayOfObject) {
  return arrayOfObject.sort((callA, callB) => {
    const dateA = new Date(callA.date)
    const dateB = new Date(callB.date)
    return dateB - dateA
  })
}

export function formatPotentialDate(inputString) {
  try {
    // Attempt to parse the input as an ISO 8601 date
    const date = parseISO(inputString)

    // Check if the parsed date is valid
    if (isValid(date)) {
      // Format and return the date in 'yyyy-MM-dd' format
      return formatDate(date)
    }
  } catch (error) {
    return inputString
  }
  return inputString
}
