import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import { usePhoneStore } from '@/stores/phone'

const axiosInstanceNoInterceptor = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL
})

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  withCredentials: true
})

// Function to refresh the token
export async function refreshToken() {
  try {
    console.log('Refreshing token')
    // Replace with your actual refresh token endpoint
    const response = await axiosInstanceNoInterceptor.post(
      '/api/refresh-token',
      {},
      {
        withCredentials: true
      }
    )
    // Update your local storage and Axios headers with the new token here

    return response.data.accessToken
  } catch (error) {
    // Handle errors (e.g., redirect to login)
    throw error
    return null
  }
}

axiosInstance.interceptors.response.use(
  (response) => response, // Return successful responses as-is
  async (error) => {
    const originalRequest = error.config
    const authStore = useAuthStore()

    // Check if it's a 401 error and the request hasn't been retried yet
    if (error.response.status === 401 && error.response.data.error === 'salesforce_invalid_grant') {
      const phoneStore = usePhoneStore()
      phoneStore.errorMessage = 'Salesforce connection error, go to the settings to reconnect'
      return Promise.reject(error)
    }

    if (error.response.status === 401 && error.response.data.error === 'pipedrive_invalid_grant') {
      const phoneStore = usePhoneStore()
      phoneStore.errorMessage = 'Pipedrive connection error, go to the settings to reconnect'
      return Promise.reject(error)
    }

    if (error.response.status === 401 && error.response.data.error === 'hubspot_invalid_grant') {
      const phoneStore = usePhoneStore()
      phoneStore.errorMessage = 'Hubspot connection error, go to the settings to reconnect'
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        // Attempt to refresh the token
        await refreshToken()

        // The browser will automatically include the new token in the cookie for this request
        return axios(originalRequest)
      } catch (refreshError) {
        authStore.handleUnauthorizedAccess()
        // Token refresh failed, handle accordingly (e.g., log out the user, redirect to login)
        return Promise.reject(refreshError)
      }
    }

    // For other types of errors or if the request has already been retried, just reject
    return Promise.reject(error)
  }
)

export default axiosInstance
