<template>
  <div class="search-contacts" v-if="contactStore.searchContactOpened">
    <div class="overlay"></div>

    <div class="search-contacts-modal" ref="searchContactModal">
      <div class="search-input">
        <input
          type="text"
          ref="inputField"
          v-model="searchTerm"
          @input="debouncedSearchContacts"
          placeholder="Search contact..."
          class="input__text"
          @keydown.down.prevent="focusNextItem"
          @keydown.up.prevent="focusPreviousItem"
          @keydown.enter.prevent="selectHighlightedItem"
          @keydown.esc.prevent="closeDropdown"
          :aria-expanded="contactStore.searchContactOpened"
          aria-controls="searchContactDropdown"
          aria-label="Search contact..."
          :disabled="contactStore.contactLoading"
        />
        <span class="input__icon" @click="focusInput">
          <SearchIcon class="search-icon" size="18" />
        </span>
      </div>
      <div class="search-contacts__list" ref="searchContactDropdown" role="listbox">
        <div v-if="contactStore.contactLoading" class="empty-state">
          <Loader :size="20" :borderWidth="2" border-color="#ababab" />
        </div>
        <div v-else>
          <div v-if="contactStore.searchLoading" class="empty-state">
            <Loader :size="20" :borderWidth="2" border-color="#ababab" />
          </div>
          <div
            v-else-if="contacts.length === 0 && isPhoneNumberValid(searchTerm)"
            class="empty-state call-section"
          >
            No contact for this number
            <div v-if="phoneStore.isDialingOrCalling" class="call__button call__button--disabled">
              <PhoneIcon class="phone-icon phone-icon--grey" size="20" />Call in progress
            </div>
            <div v-else class="call__button" @click="callOneNumber(searchTerm)">
              <PhoneIcon class="phone-icon" size="20" />Call {{ searchTerm }}
            </div>
          </div>
          <div v-else-if="contacts.length === 0" class="empty-state">No contact</div>
          <div
            v-for="(item, index) in contacts"
            :key="item.id"
            @click="selectValue(item)"
            @mouseenter="highlightedIndex = index"
            :class="['dropdown-item', { 'dropdown-item--highlighted': highlightedIndex === index }]"
            role="option"
            :aria-selected="highlightedIndex === index"
          >
            <div class="item-list__text">
              <div class="item-list__text--name">{{ item.firstName }} {{ item.lastName }}</div>
              <div class="item-list__text--title" v-if="buildSubtitle(item)">
                {{ buildSubtitle(item) }}
              </div>
              <div
                class="item-list__text--phone"
                v-for="phone of filterPhoneNumbers(item.phoneNumbers)"
                :key="phone"
              >
                <PhoneIcon class="phone-icon" size="16" />
                {{ phone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Loader from './Loader.vue'
import SearchIcon from './icons/SearchIcon.vue'
import { onClickOutside } from '@vueuse/core'
import { nextTick, onMounted, ref, computed, watch } from 'vue'
import { debounce } from 'lodash'

const emit = defineEmits(['select:value', 'close'])

import { useContactStore } from '@/stores/contactStore'
import { usePhoneStore } from '@/stores/phone'
import PhoneIcon from './icons/PhoneIcon.vue'
import { assertIsPhoneNumberAuthorized, assertIsPhoneNumberValid } from '@/helpers/phoneNumber'
import { useSessionStore } from '../stores/sessionStore'
import { assertIsSameCountry } from '../helpers/phoneNumber'

const contactStore = useContactStore()
const phoneStore = usePhoneStore()

const filterPhoneNumbers = (phoneNumbers) => {
  return Object.values(phoneNumbers).filter((phone) => phone !== null)
}

function isPhoneNumberValid(phoneNumber) {
  try {
    assertIsPhoneNumberValid(phoneNumber)
    assertIsPhoneNumberAuthorized(phoneNumber, phoneStore.authorizedCountryCodes)
    const sessionStore = useSessionStore()
    assertIsSameCountry(sessionStore.getFromNumber, phoneNumber)
    return true
  } catch (error) {
    return false
  }
}

function callOneNumber(phoneNumber) {
  console.log('call one number', phoneNumber)
  phoneStore.startCallFromModal({ phoneNumber })
}

const searchTerm = ref('')
const inputField = ref(null)

const listLoading = ref(false)

function focusInput() {
  inputField.value.focus()
}

function buildSubtitle(contact) {
  if (!contact.company) {
    return contact.title
  }
  if (!contact.title) {
    return contact.company
  }
  if (contact.title && contact.company) {
    return `${contact.title} at ${contact.company}`
  }
  return ''
}

const contacts = ref([])

onMounted(async () => {
  await contactStore.loadInitialContacts()
  contacts.value = contactStore.initialContacts
})

const debouncedSearchContacts = debounce(async () => {
  try {
    if (!searchTerm.value.trim()) {
      contacts.value = contactStore.initialContacts
    }
    listLoading.value = true
    contacts.value = []
    const response = await contactStore.searchContacts(searchTerm.value.trim())
    contacts.value = response
  } catch (error) {
    console.error('Failed to search contacts:', error)
  } finally {
    listLoading.value = false
  }
}, 300) // 300 milliseconds

watch(
  () => searchTerm.value,
  () => {
    highlightedIndex.value = 0
    scrollToHighlightedItem()
  }
)

watch(
  () => contactStore.searchContactOpened,
  () => {
    contacts.value = contactStore.initialContacts
  }
)

const selectValue = async (value) => {
  try {
    searchTerm.value = ''

    inputField.value.blur()

    if (value.id) {
      emit('select:value', value)
    }
  } catch (error) {
    console.error('error while loading new list', error)
  }
}

const searchContactDropdown = ref(null)

function closeDropdown() {
  contactStore.searchContactOpened = false
  searchTerm.value = ''
  contacts.value = []
  emit('close')
}

const highlightedIndex = ref(-1)

const focusNextItem = () => {
  if (highlightedIndex.value < contacts.value.length - 1) {
    highlightedIndex.value++
    scrollToHighlightedItem()
  }
}

const focusPreviousItem = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--
    scrollToHighlightedItem()
  }
}

const selectHighlightedItem = () => {
  if (highlightedIndex.value >= 0 && highlightedIndex.value < contacts.value.length) {
    selectValue(contacts.value[highlightedIndex.value])
  }
}

const scrollToHighlightedItem = () => {
  const dropdown = searchContactDropdown.value
  if (dropdown) {
    const highlightedItem = dropdown.children[highlightedIndex.value]
    if (highlightedItem) {
      highlightedItem.scrollIntoView({ block: 'nearest' })
    }
  }
}

watch(
  () => contactStore.searchContactOpened,
  (newValue) => {
    if (newValue) {
      nextTick(() => focusInput())
    }
  }
)

const searchContactModal = ref(null)

onClickOutside(searchContactModal, () => {
  closeDropdown()
})
</script>

<style lang="scss" scoped>
.search-contacts {
  .search-contacts-modal {
    position: absolute;
    top: 90px;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    flex-direction: column;

    overflow: hidden;
    z-index: 4;

    box-shadow:
      rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 5px 10px,
      rgba(15, 15, 15, 0.2) 0px 15px 40px;
    border-radius: 12px;
    background-color: #ffffff;

    width: 650px;
    height: calc(-180px + 100vh);

    line-height: normal;

    .input__text {
      color: #334155;
      padding: 0.5rem 0.75rem 0.5rem calc(20px + 0.75rem);
      border: none;
      outline: none;
      width: 100%;

      box-sizing: border-box;
      transition: border-color 0.3s;
      font-weight: 500;
      &:disabled {
        background-color: #ffffff;
      }
    }

    .input__icon {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      color: #c2c2c2;
      .search-icon {
        stroke: #c2c2c2;
      }
    }
    input::placeholder {
      font-weight: 500;
    }
    .search-contacts__list {
      padding-bottom: 12px;
      color: #344054;
      font-size: 14px;
      display: inline-block;
      font-weight: 500;
      overflow: visible;
      overflow-y: auto;
      .dropdown-item {
        padding: 8px;
        cursor: pointer;

        border-bottom: 1px solid #eaecf0;
        display: flex;
        align-items: center;
        padding: 16px 0 16px 24px;
        transition: background 0.027s ease;
        color: #2b2c2e;

        .item-list__text {
          font-size: 14px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          line-height: 14px;
          flex: 1;
          &--name {
            margin-bottom: 12px;
            color: #37352f;
          }
          &--title {
            margin-bottom: 12px;
            color: #667085;
            font-size: 13px;
          }
          &--phone {
            color: #667085;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          }
        }
      }
      .dropdown-item--highlighted {
        background-color: #f0f0f0;
      }
    }
    .empty-state {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    .call-section {
      display: flex;
      align-items: center;
    }

    .search-input {
      position: relative;
      border-bottom: 1px solid #eaecf0;
      padding: 8px 12px;
      font-size: 16px;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(15, 15, 15, 0.6);
  }
}

.call__button {
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  color: #37352f;
  background: #fff;
  margin-left: 8px;

  &:hover {
    cursor: pointer;
    border-color: #c2c2c2;
  }

  &--disabled {
    color: #b8b8b8;
    background: #f6f6f6;
    user-select: none;
    border: 1px solid #f6f6f6;

    &:hover {
      cursor: unset;
      border-color: #f6f6f6;
    }
  }
}
.phone-icon {
  margin-right: 7px;
  stroke: #667085;

  &--grey {
    stroke: #b8b8b8;
  }
}
</style>
